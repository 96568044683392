import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import FlowIndicator from "../components/hoc/flowIndicator/flowIndicator"

const Success = () => {

    const i18n = useIntl().formatMessage

    return (
        <>
        <SEO title="Success" />
        <section className="section-padding-big">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-10 col-lg-8 col-xl-6">
                    <h1 className="text-center h2">{ i18n({ id: "pages.register.title" })}</h1>
                    <p className="text-center" >{ i18n({ id: "pages.register.description" })}</p>
                       <FlowIndicator  steps={ [
                            {name: i18n({ id: "pages.register.steps.registration" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.info" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.design" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.payment" }), active: false, complete: true}
                        ]}/>

                    <div className="card text-center">
                        <h3>Din betaling er modtaget</h3>
                        <p>Du kan nu gå i gang med at redigere og udgive din side.</p>
                        <p>Vi har lavet en skabelon for dig, ud fra dine valg. Vi anbefaler dog, at du ændrer i teksterne, så de passer til netop din virksomhed.</p>
                        <Link to="/app" className="btn">Gå til din side</Link>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
    }

export default Success
