import React from "react"

import FlowStyles from './flowIndicator.module.scss'

const FlowIndicator = ({ steps }) => {

    return (
    <div className={FlowStyles.wrapper}>
        <div className={FlowStyles.line}></div>
        { steps.map((s, i) => 
            <div key={i} className={[FlowStyles.circle, s.active ? FlowStyles.active : null, s.complete ? FlowStyles.complete : null].join(' ')}>
                <p>{ s.name }</p>
            </div>
        )}
    </div>
)}

export default FlowIndicator
